<template>
  <label v-bind:for="component_id" v-if="component_meta.component.properties.show_label == 'Y'">
    {{ component_meta.label }}：
  </label>
  <span class="tip" v-if="component_meta.component.properties.show_label == 'Y'">{{ component_meta.tip }}</span>

  <input v-show="!component_to_label" type="date" class="form-control"
    v-bind:class="{ 'is-invalid': component_message != '' && component_message != undefined }" v-bind:id="component_id"
    v-bind:name="component_meta.variable" v-model="component_value" @change="this.$emit('input', $event)" />
  <div v-show="component_to_label">
    {{ component_value }}
  </div>
  <div class="invalid-mssage">{{ component_message }}</div>
</template>

<script>
export default {
  props: ['field', 'value', 'message'],
  emits: ['input'],
  data() {
    let localValue = (this.value == undefined) ? Date().now : this.value;
    if (localValue.length > 10) {
      localValue = localValue.substring(0, 10);
    }

    return {

      // 元件識別碼
      component_id: 'date-' + Math.random().toString().substring(2),

      // 欄位資訊
      component_meta: this.field,

      // 元件值
      component_value: localValue,

      // 元件訊息
      component_message: this.message,

      // 元件是否被標籤化
      component_to_label: (this.field.component.properties.to_label == 'Y') ? true : false
    }
  },
  watch: {
    message: function (new_value, _) {
      _;
      this.component_message = new_value;

    }
  },
  methods: {
    validate: function (value) {

      this.component_message = '';

      // 是否必填
      if (this.component_meta.component.properties.required == 'Y' && value == '') {
        this.component_message = '必填';
      }

      return this.component_message;
    },
  }
}
</script>

<style>
span.tip {
  color: green;
}

div.invalid-mssage {
  color: red;
  font-size: 12px;
  margin-top: 3px;
}
</style>